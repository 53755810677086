<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="
                    (item.active && 'active') ||
                    (item.finish && 'navtop-canclick')
                  "
                  @click="
                    item.finish &&
                      stepwh_signup < 8 &&
                      changeStep(item.linkItems)
                  "
                >
                  <a href="" class="d-flex flex-column">
                    <span v-if="item.finish" class="icons"
                      ><img src="@/assets/icons/icon-checkmark.svg" alt=""
                    /></span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep3.all_rateplan") }}</h3>

              <div class="form-group">
                <div
                  class="dropdown select"
                  @click="getfocus('rateplan')"
                  @mouseleave="mouseOver('rateplan')"
                >
                  <a data-toggle="dropdown" data-display="static" id="rateplan">
                    <v-select
                      v-model="addNewRatePlan.newrateplan.rateplan"
                      :items="itemsRatePlan"
                      item-text="name"
                      item-value="value"
                      label=""
                      :placeholder="$t('SignupStep3.select_add_rateplan')"
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      @change="checkcanuseperFunc()"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content
                          @mouseenter="getfocus('rateplan')"
                          @mouseleave="mouseOver('rateplan')"
                        >
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </a>
                </div>
              </div>

              <div v-if="showperroom || showperguest" class="form-group pt-3">
                <label class="light">{{
                  $t("SignupStep3.whattypeofper")
                }}</label>

                <div
                  v-if="addNewRatePlan.newrateplan.rateplan"
                  class="form-group d-flex justify-content-center pt-3"
                >
                  <div v-if="showperroom" class="radio-group">
                    <input
                      id="per_room"
                      :value="addNewRatePlan.newrateplan.rateplanper == 'night'"
                      name="rateplan"
                      type="radio"
                      @change="changePlanPer('night')"
                      :checked="
                        addNewRatePlan.newrateplan.rateplanper == 'night'
                      "
                    />
                    <label for="per_room"
                      ><span>{{ $t("SignupStep3.pernight") }}</span></label
                    >
                  </div>

                  <div v-if="showperguest" class="radio-group ml-5">
                    <input
                      id="per_guest"
                      :value="
                        addNewRatePlan.newrateplan.rateplanper == 'person'
                      "
                      name="rateplan"
                      type="radio"
                      @change="changePlanPer('person')"
                      :checked="
                        addNewRatePlan.newrateplan.rateplanper == 'person'
                      "
                    />
                    <label for="per_guest"
                      ><span>{{ $t("SignupStep3.perperson") }}</span></label
                    >
                  </div>
                </div>
              </div>

              <div
                v-if="
                  addNewRatePlan.newrateplan.rateplan &&
                  addNewRatePlan.newrateplan.roomtypes.length
                "
                class="box-rateplan"
              >
                <div class="box-inner per_room active">
                  <table
                    v-if="addNewRatePlan.newrateplan.rateplanper == 'night'"
                    class="table table-roomrate"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th v-if="showbaserate" class="col-rate">
                          {{ $t("SignupStep3.adultrate") }}
                        </th>
                        <th class="col-adult">
                          {{ $t("SignupStep3.extrabed")
                          }}<small>({{ $t("SignupStep3.adult") }})</small>
                        </th>
                        <th class="col-child">
                          {{ $t("SignupStep3.extrabed")
                          }}<small>({{ $t("SignupStep3.child") }})</small>
                        </th>
                        <th class="col-del"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(item, index) in addNewRatePlan.newrateplan
                          .roomtypes"
                      >
                        <tr class="title-mobile" :key="index + 999">
                          <th colspan="5">{{ item.name }}</th>
                        </tr>
                        <tr :key="index">
                          <th
                            :style="
                              !item.baserateprice
                                ? 'vertical-align: top !important'
                                : 'vertical-align: middle !important'
                            "
                          >
                            {{ item.name }}
                          </th>
                          <td
                            v-if="showbaserate"
                            :style="
                              !item.baserateprice ||
                              (item.customer_normal < item.customer_max &&
                                !item.extraadult) ||
                              (item.customer_normal < item.customer_max &&
                                !item.extrachild)
                                ? 'vertical-align: top !important'
                                : 'vertical-align: middle !important'
                            "
                          >
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control"
                              :class="
                                !item.baserateprice && 'border-pink-focus'
                              "
                              placeholder=""
                              name=""
                              v-model="item.baserateprice"
                              @keyup="checkcanadd()"
                            />
                            <span
                              class="error-text-input"
                              v-if="!item.baserateprice"
                              >{{ $t("Alert.pleasefilldata") }}</span
                            >
                          </td>
                          <td
                            :style="
                              !item.baserateprice ||
                              (item.customer_normal < item.customer_max &&
                                !item.extraadult) ||
                              (item.customer_normal < item.customer_max &&
                                !item.extrachild)
                                ? 'vertical-align: top !important'
                                : 'vertical-align: middle !important'
                            "
                          >
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control"
                              :class="
                                item.customer_normal < item.customer_max &&
                                !item.extraadult &&
                                'border-pink-focus'
                              "
                              placeholder=""
                              name=""
                              v-model="item.extraadult"
                              :disabled="
                                item.customer_normal >= item.customer_max
                              "
                              @keyup="checkcanadd()"
                            />
                            <span
                              class="error-text-input"
                              v-if="
                                item.customer_normal < item.customer_max &&
                                !item.extraadult
                              "
                              >{{ $t("Alert.pleasefilldata") }}</span
                            >
                          </td>
                          <td
                            :style="
                              !item.baserateprice ||
                              (item.customer_normal < item.customer_max &&
                                !item.extraadult) ||
                              (item.customer_normal < item.customer_max &&
                                !item.extrachild)
                                ? 'vertical-align: top !important'
                                : 'vertical-align: middle !important'
                            "
                          >
                            <input
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control"
                              :class="
                                item.customer_normal < item.customer_max &&
                                !item.extrachild &&
                                'border-pink-focus'
                              "
                              placeholder=""
                              name=""
                              v-model="item.extrachild"
                              :disabled="
                                item.customer_normal >= item.customer_max
                              "
                              @keyup="checkcanadd()"
                            />
                            <span
                              class="error-text-input"
                              v-if="
                                item.customer_normal < item.customer_max &&
                                !item.extrachild
                              "
                              >{{ $t("Alert.pleasefilldata") }}</span
                            >
                          </td>
                          <td
                            class="col-del"
                            :style="
                              !item.baserateprice ||
                              (item.customer_normal < item.customer_max &&
                                !item.extraadult) ||
                              (item.customer_normal < item.customer_max &&
                                !item.extrachild)
                                ? 'vertical-align: top !important; padding-top: 0.75rem !important;'
                                : 'vertical-align: middle !important'
                            "
                          >
                            <a
                              @mouseenter="item.hover = true"
                              @mouseleave="item.hover = false"
                              @click="removeNewRatePlanRoomtype(item)"
                              :disabled="
                                addNewRatePlan.newrateplan.roomtypes.length ===
                                1
                              "
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M12.7584 7.24609C12.4997 7.24609 12.29 7.45577 12.29 7.71448V16.5669C12.29 16.8255 12.4997 17.0353 12.7584 17.0353C13.0171 17.0353 13.2268 16.8255 13.2268 16.5669V7.71448C13.2268 7.45577 13.0171 7.24609 12.7584 7.24609Z"
                                    :fill="item.hover ? '#EE3068' : '#333333'"
                                  />
                                  <path
                                    d="M7.23206 7.24609C6.97335 7.24609 6.76367 7.45577 6.76367 7.71448V16.5669C6.76367 16.8255 6.97335 17.0353 7.23206 17.0353C7.49076 17.0353 7.70044 16.8255 7.70044 16.5669V7.71448C7.70044 7.45577 7.49076 7.24609 7.23206 7.24609Z"
                                    :fill="item.hover ? '#EE3068' : '#333333'"
                                  />
                                  <path
                                    d="M3.20431 5.95419V17.4942C3.20431 18.1762 3.45442 18.8168 3.89133 19.2764C4.32623 19.7373 4.93147 19.9989 5.56488 20H14.4268C15.0604 19.9989 15.6657 19.7373 16.1004 19.2764C16.5373 18.8168 16.7874 18.1762 16.7874 17.4942V5.95419C17.6559 5.72366 18.2187 4.8846 18.1025 3.99339C17.9862 3.10236 17.2271 2.43583 16.3284 2.43565H13.9303V1.85017C13.933 1.35782 13.7383 0.885049 13.3898 0.537238C13.0413 0.18961 12.5678 -0.00396362 12.0754 6.15416e-05H7.91631C7.42396 -0.00396362 6.95045 0.18961 6.60191 0.537238C6.25337 0.885049 6.0587 1.35782 6.06144 1.85017V2.43565H3.66336C2.76465 2.43583 2.00554 3.10236 1.88918 3.99339C1.773 4.8846 2.33579 5.72366 3.20431 5.95419ZM14.4268 19.0632H5.56488C4.76406 19.0632 4.14107 18.3753 4.14107 17.4942V5.99536H15.8506V17.4942C15.8506 18.3753 15.2277 19.0632 14.4268 19.0632ZM6.99821 1.85017C6.9951 1.60628 7.09097 1.37154 7.26405 1.19938C7.43695 1.02721 7.67224 0.932619 7.91631 0.936827H12.0754C12.3195 0.932619 12.5548 1.02721 12.7277 1.19938C12.9007 1.37136 12.9966 1.60628 12.9935 1.85017V2.43565H6.99821V1.85017ZM3.66336 3.37242H16.3284C16.794 3.37242 17.1714 3.74987 17.1714 4.21551C17.1714 4.68114 16.794 5.05859 16.3284 5.05859H3.66336C3.19772 5.05859 2.82027 4.68114 2.82027 4.21551C2.82027 3.74987 3.19772 3.37242 3.66336 3.37242Z"
                                    :fill="item.hover ? '#EE3068' : '#333333'"
                                  />
                                  <path
                                    d="M9.99572 7.24609C9.73702 7.24609 9.52734 7.45577 9.52734 7.71448V16.5669C9.52734 16.8255 9.73702 17.0353 9.99572 17.0353C10.2544 17.0353 10.4641 16.8255 10.4641 16.5669V7.71448C10.4641 7.45577 10.2544 7.24609 9.99572 7.24609Z"
                                    :fill="item.hover ? '#EE3068' : '#333333'"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <table v-else class="table table-roomrate">
                    <thead>
                      <tr>
                        <th></th>
                        <th class="col-rate">
                          {{ $t("SignupStep3.adultrate") }}
                        </th>
                        <th class="col-rate">
                          {{ $t("SignupStep3.childrate") }}
                        </th>
                        <th class="col-del"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(item, index) in addNewRatePlan.newrateplan
                          .roomtypes"
                      >
                        <tr class="title-mobile" :key="index + 999">
                          <th colspan="5">{{ item.name }}</th>
                        </tr>
                        <tr :key="index">
                          <th
                            :style="
                              !item.adultrate || !item.childrate
                                ? 'vertical-align: top !important'
                                : 'vertical-align: middle !important'
                            "
                          >
                            {{ item.name }}
                          </th>
                          <td
                            :style="
                              !item.adultrate || !item.childrate
                                ? 'vertical-align: top !important'
                                : 'vertical-align: middle !important'
                            "
                          >
                            <input
                              :id="`adultrate_${item._id}`"
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control"
                              :class="!item.adultrate && 'border-pink-focus'"
                              placeholder=""
                              name=""
                              v-model="item.adultrate"
                              @keyup="checkcanadd()"
                            />
                            <span
                              class="error-text-input"
                              v-if="!item.adultrate"
                              >{{ $t("Alert.pleasefilldata") }}</span
                            >
                          </td>
                          <td
                            :style="
                              !item.adultrate || !item.childrate
                                ? 'vertical-align: top !important'
                                : 'vertical-align: middle !important'
                            "
                          >
                            <input
                              :id="`childrate_${item._id}`"
                              type="number"
                              min="0"
                              @keypress="isNumber($event)"
                              class="form-control"
                              :class="!item.childrate && 'border-pink-focus'"
                              placeholder=""
                              name=""
                              v-model="item.childrate"
                              @keyup="checkcanadd()"
                            />
                            <span
                              class="error-text-input"
                              v-if="!item.childrate"
                              >{{ $t("Alert.pleasefilldata") }}</span
                            >
                          </td>
                          <td
                            class="col-del"
                            :style="
                              !item.adultrate || !item.childrate
                                ? 'vertical-align: top !important; padding-top: 0.75rem !important;'
                                : 'vertical-align: middle !important'
                            "
                          >
                            <a
                              @mouseenter="item.hover = true"
                              @mouseleave="item.hover = false"
                              @click="removeNewRatePlanRoomtype(item)"
                              :disabled="
                                addNewRatePlan.newrateplan.roomtypes.length ===
                                1
                              "
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M12.7584 7.24609C12.4997 7.24609 12.29 7.45577 12.29 7.71448V16.5669C12.29 16.8255 12.4997 17.0353 12.7584 17.0353C13.0171 17.0353 13.2268 16.8255 13.2268 16.5669V7.71448C13.2268 7.45577 13.0171 7.24609 12.7584 7.24609Z"
                                    :fill="item.hover ? '#EE3068' : '#333333'"
                                  />
                                  <path
                                    d="M7.23206 7.24609C6.97335 7.24609 6.76367 7.45577 6.76367 7.71448V16.5669C6.76367 16.8255 6.97335 17.0353 7.23206 17.0353C7.49076 17.0353 7.70044 16.8255 7.70044 16.5669V7.71448C7.70044 7.45577 7.49076 7.24609 7.23206 7.24609Z"
                                    :fill="item.hover ? '#EE3068' : '#333333'"
                                  />
                                  <path
                                    d="M3.20431 5.95419V17.4942C3.20431 18.1762 3.45442 18.8168 3.89133 19.2764C4.32623 19.7373 4.93147 19.9989 5.56488 20H14.4268C15.0604 19.9989 15.6657 19.7373 16.1004 19.2764C16.5373 18.8168 16.7874 18.1762 16.7874 17.4942V5.95419C17.6559 5.72366 18.2187 4.8846 18.1025 3.99339C17.9862 3.10236 17.2271 2.43583 16.3284 2.43565H13.9303V1.85017C13.933 1.35782 13.7383 0.885049 13.3898 0.537238C13.0413 0.18961 12.5678 -0.00396362 12.0754 6.15416e-05H7.91631C7.42396 -0.00396362 6.95045 0.18961 6.60191 0.537238C6.25337 0.885049 6.0587 1.35782 6.06144 1.85017V2.43565H3.66336C2.76465 2.43583 2.00554 3.10236 1.88918 3.99339C1.773 4.8846 2.33579 5.72366 3.20431 5.95419ZM14.4268 19.0632H5.56488C4.76406 19.0632 4.14107 18.3753 4.14107 17.4942V5.99536H15.8506V17.4942C15.8506 18.3753 15.2277 19.0632 14.4268 19.0632ZM6.99821 1.85017C6.9951 1.60628 7.09097 1.37154 7.26405 1.19938C7.43695 1.02721 7.67224 0.932619 7.91631 0.936827H12.0754C12.3195 0.932619 12.5548 1.02721 12.7277 1.19938C12.9007 1.37136 12.9966 1.60628 12.9935 1.85017V2.43565H6.99821V1.85017ZM3.66336 3.37242H16.3284C16.794 3.37242 17.1714 3.74987 17.1714 4.21551C17.1714 4.68114 16.794 5.05859 16.3284 5.05859H3.66336C3.19772 5.05859 2.82027 4.68114 2.82027 4.21551C2.82027 3.74987 3.19772 3.37242 3.66336 3.37242Z"
                                    :fill="item.hover ? '#EE3068' : '#333333'"
                                  />
                                  <path
                                    d="M9.99572 7.24609C9.73702 7.24609 9.52734 7.45577 9.52734 7.71448V16.5669C9.52734 16.8255 9.73702 17.0353 9.99572 17.0353C10.2544 17.0353 10.4641 16.8255 10.4641 16.5669V7.71448C10.4641 7.45577 10.2544 7.24609 9.99572 7.24609Z"
                                    :fill="item.hover ? '#EE3068' : '#333333'"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                v-if="addNewRatePlan.newrateplan.rateplan"
                class="form-group-roomname has-button mt-4"
              >
                <!-- <div
                  class="dropdown select"
                  @click="getfocus('newrateplanroomtype')"
                  @mouseleave="mouseOver('newrateplanroomtype')"
                >
                  <a
                    data-toggle="dropdown"
                    data-display="static"
                    id="newrateplanroomtype"
                    style="min-height: 3rem !important"
                  >
                    <v-autocomplete
                      v-model="addNewRatePlan.newrateplanroomtype"
                      :items="itemsRoomType"
                      item-text="name"
                      item-value="_id"
                      label=""
                      :placeholder="$t('SignupStep3.place_addroomname')"
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      multiple
                      style="max-height: 3rem !important"
                      @mouseenter="getfocus('newrateplanroomtype')"
                      @mouseleave="mouseOver('newrateplanroomtype')"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{
                            item.name.length > 19
                              ? `${item.name.slice(0, 20)}...`
                              : item.name
                          }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text">
                          (+{{ addNewRatePlan.newrateplanroomtype.length - 1 }}
                          {{ $t("SignupStep4.unit_room_names") }})
                        </span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleAllRooms">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                addNewRatePlan.newrateplanroomtype.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{$t('SignupStep3.place_addallroomname')}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </a>
                </div>
                <button
                  class="btn btn-add btn-addtype"
                  style="min-height: 3rem !important"
                  type="button"
                  @click="addNewRatePlanRoomtype"
                  :disabled="!canaddroom"
                >
                  <span style="text-transform: none">{{
                    $t("SignupStep3.btn_addrateplan")
                  }}</span>
                </button> -->
                <v-dialog
                  v-model="dialogAddRoomName"
                  width="500"
                  @keydown.esc="closeDialogAddRoomName()"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      class="btn btn-add-roomname btn-addtype"
                      style="min-height: 3rem !important"
                      type="button"
                      @click="addNewRatePlanRoomtype"
                      :disabled="
                        addNewRatePlan.newrateplanroomtype.length ==
                        itemsRoomType.length
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span style="text-transform: none">{{
                        $t("SignupStep3.btn_addroomname")
                      }}</span>
                    </button>
                  </template>

                  <v-card>
                    <v-card-title
                      class="d-flex flex-row align-center justify-space-between"
                    >
                      <span class="dialog-title">{{
                        $t("SignupStep3.title_addroomname")
                      }}</span>
                      <v-btn
                        icon
                        color="#EE3068"
                        @click="closeDialogAddRoomName"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <div
                        class="dropdown select"
                        @click="getfocus('newrateplanroomtype')"
                        @mouseleave="mouseOver('newrateplanroomtype')"
                      >
                        <a
                          data-toggle="dropdown"
                          data-display="static"
                          id="newrateplanroomtype"
                          style="min-height: 3rem !important"
                        >
                          <v-autocomplete
                            v-model="addNewRatePlan.newrateplanroomtype"
                            :items="itemsRoomType"
                            item-text="name"
                            item-value="_id"
                            label=""
                            :placeholder="$t('SignupStep3.place_addroomname')"
                            :no-data-text="$t('SignupStep.nodata')"
                            color="pink"
                            solo
                            flat
                            dense
                            single-line
                            hide-details
                            multiple
                            style="max-height: 3rem !important"
                            @mouseenter="getfocus('newrateplanroomtype')"
                            @mouseleave="mouseOver('newrateplanroomtype')"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index === 0">
                                <span>{{
                                  item.name.length > 19
                                    ? `${item.name.slice(0, 20)}...`
                                    : item.name
                                }}</span>
                              </v-chip>
                              <span v-if="index === 1" class="grey--text">
                                (+{{
                                  addNewRatePlan.newrateplanroomtype.length - 1
                                }}
                                {{ $t("SignupStep4.unit_room_names") }})
                              </span>
                            </template>
                            <template v-slot:prepend-item>
                              <v-list-item ripple @click="toggleAllRooms">
                                <v-list-item-action>
                                  <v-icon
                                    :color="
                                      addNewRatePlan.newrateplanroomtype
                                        .length > 0
                                        ? 'indigo darken-4'
                                        : ''
                                    "
                                  >
                                    {{ icon }}
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    $t("SignupStep3.place_addallroomname")
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                          </v-autocomplete>
                        </a>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="#EE3068"
                        text
                        class="dialog-btn-close"
                        @click="closeDialogAddRoomName"
                      >
                        {{ $t("SignupStep1.btn_cancel") }}
                      </v-btn>
                      <v-btn
                        color="#EE3068"
                        dark
                        class="dialog-btn-ok"
                        @click="addNewRatePlanRoomtype"
                      >
                        {{ $t("SignupStep4.btn_ok") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <div
              class="pa-0 d-flex flex-row align-center justify-space-between flex-100per"
            >
              <button
                type="button"
                class="btn-back gl-bt hover_btn ml-0"
                style="text-transform: capitalize !important"
                @click="goback()"
              >
                <span>{{ `< ${$t("servicepage.back")}` }}</span>
              </button>

              <button
                class="btn xs gl-bt hover_btn mx-0"
                type="button"
                :key="componentKey"
                :disabled="!canaddrateplan"
                @click="addNewVoucherDataFunc()"
              >
                <span>{{ $t("SignupStep1.btn_save") }}</span>
              </button>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a
              :href="pathrightphoto"
              class="img-exemple iphone-model d-flex flex-column align-center justify-center"
              data-fancybox
            >
              <span class="btn-open-picture">{{
                $t("SignupStep4.clickopenpicture")
              }}</span>
              <img :src="pathrightphoto" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import { el } from "date-fns/locale";

export default {
  name: "RatePlanCreateScreen",
  metaInfo: {
    title: "Add Rate plans and vouchers",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    stepwh_signup: 3,
    itemsRatePlan: [],
    itemsRatePlanData: [],
    itemsDefaultRoomType: [],
    itemsDefaultRatePlan: [],
    itemsRoomType: [],
    addNewRatePlan: {
      newrateplan: {
        rateplan: "",
        rateplanper: "night",
        roomtypes: [],
      },
      newrateplanroomtype: "",
    },
    roomData: {
      rateplans: [],
    },
    propertyID: "",
    value_element: "",
    componentKey: 0,
    itemsStep: [],
    roomTypies: [],
    pathrightphoto: "",
    canaddrateplan: false,
    showbaserate: true,
    userToken: "",
    canaddroom: false,
    showperroom: true,
    showperguest: true,
    dialogAddRoomName: false,
    advance_booking: 0,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (self.userToken && self.propertyID) {
      self.changeLanguageData();
    } else {
      self.gotosignin();
    }
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    localStorage.setItem("signupstep", self.stepwh_signup);
    localStorage.setItem("SignupStep", self.stepwh_signup);
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.changeLanguageData);
    this.colorHover();
  },
  watch: {
    "addNewRatePlan.newrateplan.rateplan"() {
      this.checkcanadd();
    },
    "addNewRatePlan.newrateplan.rateplanper"() {
      this.checkcanadd();
      if (this.addNewRatePlan.newrateplan.rateplanper == "person")
        this.showbaserate = false;
      else this.showbaserate = true;
    },
    "addNewRatePlan.newrateplan.roomtypes"() {
      this.checkcanadd();
    },
    "addNewRatePlan.newrateplanroomtype"() {
      const self = this;
      if (self.addNewRatePlan.newrateplanroomtype) self.canaddroom = true;
      else self.canaddroom = false;
      self.forceRerender();
    },
    itemsDefaultRatePlan() {
      const self = this;
      self.checkcanuserateplanFunc();
    },
    itemsRatePlanData() {
      const self = this;
      self.checkcanuserateplanFunc();
    },
  },
  computed: {
    selectedAllRooms() {
      return (
        this.addNewRatePlan.newrateplanroomtype.length ===
        this.itemsRoomType.length
      );
    },
    selectedSomeRoom() {
      return (
        this.addNewRatePlan.newrateplanroomtype.length > 0 &&
        !this.selectedAllRooms
      );
    },
    icon() {
      if (this.selectedAllRooms) return "mdi-close-box";
      if (this.selectedSomeRoom) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    changeLanguageData() {
      const self = this;
      self.initStepData();
      self.initRatePlanTypes();
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkcanadd() {
      const self = this;
      var numofchoices = 0;
      var numofchoicesdata = 0;
      if (self?.addNewRatePlan?.newrateplan?.roomtypes?.length) {
        if (self?.addNewRatePlan?.newrateplan?.rateplanper == "night") {
          self?.addNewRatePlan?.newrateplan?.roomtypes?.map((ele) => {
            if (
              !ele?.baserateprice ||
              ele?.baserateprice == "" ||
              Number(ele?.baserateprice) < 0 ||
              Number(ele?.baserateprice) == 0
            ) {
              numofchoices = 0;
              // self.canaddrateplan = false;
            } else numofchoices = numofchoices + 1;
          });
        } else {
          self?.addNewRatePlan?.newrateplan?.roomtypes?.map((ele) => {
            if (
              !ele?.adultrate ||
              ele?.adultrate == "" ||
              Number(ele?.adultrate) < 0 ||
              Number(ele?.adultrate) == 0 ||
              !ele?.childrate ||
              ele?.childrate == "" ||
              Number(ele?.childrate) < 0 ||
              Number(ele?.childrate) == 0
            ) {
              numofchoices = 0;
              // self.canaddrateplan = false;
            } else numofchoices = numofchoices + 1;
          });
        }
      }
      // if (!self?.addNewRatePlan?.newrateplan?.rateplan
      // || !self?.addNewRatePlan?.newrateplan?.rateplanper) {
      //   self.canaddrateplan = false
      // } else if (self?.addNewRatePlan?.newrateplan?.roomtypes?.length == 0) {
      //   self.canaddrateplan = false
      // } else {
      //   if (numofchoices) {
      //     self.canaddrateplan = true
      //   }
      // }
      if (self?.addNewRatePlan?.newrateplan?.rateplan || numofchoices) {
        self.canaddrateplan = true;
      } else self.canaddrateplan = false;
    },
    async initStepData() {
      const self = this;
      var temp = {};
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0];
          self.advance_booking = temp?.book_in_advance || 0;
          self.roomTypies = temp.rooms;
          self.initDefaultRatePlanDatatypies(temp.rate_plan_price);
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 5,
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 6,
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 7,
              },
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        self.gotosignin();
      } finally {
        self.getStepData(self.itemsStep);
        self.initPropertyIDData();
      }
    },
    gotosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
    },
    changePhotoRight() {
      const self = this;
      var tempLG = localStorage.getItem("language") === "th" ? "th" : "en";
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/step3create-${tempLG}.gif`;
    },
    changePlanPer(val) {
      const self = this;
      self.addNewRatePlan.newrateplan.rateplanper = val;
    },
    setRoomType() {
      const self = this;
      const temp = [];
      self?.itemsDefaultRoomType?.map((el) => {
        const tempRoomName =
          self?.addNewRatePlan?.newrateplan?.roomtypes?.findIndex(
            (p) => p.roomnameid == el.roomnameid
          );
        if (tempRoomName < 0) {
          temp.push(el);
        }
      });
      self.itemsRoomType = temp;
    },
    async initRatePlanTypes() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/rate_plan",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          res?.data?.result?.map((el) => {
            temp.push({
              _id: el._id,
              name: el.value,
              value: el._id,
            });
          });
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsDefaultRatePlan = temp;
      }
    },
    checkcanuserateplanFunc() {
      const self = this;
      self.showperroom = true;
      self.showperguest = true;
      self.itemsRatePlan = [];
      var temp = [];
      if (self?.itemsRatePlanData?.length) {
        self?.itemsDefaultRatePlan?.map((el) => {
          const checkRP = self.itemsRatePlanData.filter(
            (p) => p.rate_plan == el.value
          );
          if (checkRP?.length < 2) {
            temp.push(el);
          }
        });
      } else {
        temp = self.itemsDefaultRatePlan;
      }
      self.itemsRatePlan = temp;
    },
    checkcanuseperFunc() {
      const self = this;
      const temp = self?.itemsRatePlanData?.find(
        (p) => p.rate_plan == self.addNewRatePlan.newrateplan.rateplan
      );
      if (temp?.per_room) {
        self.showperroom = false;
        self.showperguest = true;
        self.changePlanPer("person");
      } else if (temp?.per_guest) {
        self.showperroom = true;
        self.showperguest = false;
        self.changePlanPer("night");
      } else {
        self.showperroom = true;
        self.showperguest = true;
        self.changePlanPer("night");
      }
    },
    initPropertyIDData() {
      const self = this;
      var temp = [];
      var tempRoomTypes = [];
      self?.roomTypies?.map((el, index) => {
        // var indexroomtype = temp.findIndex(p => p.value == el.room_type._id)
        // if (indexroomtype < 0) {
        temp.push({
          _id: el._id,
          showname: `${el.room_name.value} (${el.room_type.value})`,
          name: el.room_name.value,
          value: el.room_name._id,
          roomtype: el.room_type.value,
          roomtypeid: el.room_type._id,
          typename: el.room_type.value,
          roomname: el.room_name.value,
          roomnameid: el.room_name._id,
          customer_normal: el?.customer?.normal || 0,
          customer_max: el?.customer?.max || 0,
        });
        // }
        // if (index < 1) {
        tempRoomTypes.push({
          _id: el._id,
          showname: `${el.room_name.value} (${el.room_type.value})`,
          name: el.room_name.value,
          value: el.room_name._id,
          roomtype: el.room_type.value,
          roomtypeid: el.room_type._id,
          roomname: el.room_name.value,
          roomnameid: el.room_name._id,
          customer_normal: el?.customer?.normal || 0,
          customer_max: el?.customer?.max || 0,
          baserateprice: "",
          extraadult: "",
          extrachild: "",
          adultrate: "",
          childrate: "",
          hover: false,
        });
        // }
      });
      self.itemsRoomType = temp;
      self.itemsDefaultRoomType = temp;
      self.addNewRatePlan.newrateplan.roomtypes = tempRoomTypes;
      self.setRoomType();
    },
    initDefaultRatePlanDatatypies(item) {
      const self = this;
      var temp = [];
      var tempRoomTypies = [];
      if (item?.length) {
        item?.map((el) => {
          tempRoomTypies = [];
          el?.rooms?.map((ele) => {
            const tempRoomName = self.itemsRoomType.find(
              (p) => p.value == ele?.room_name?._id
            );
            tempRoomTypies.push({
              _id: ele._id,
              room:
                tempRoomName?._id || typeof ele?.room == "object"
                  ? ele?.room?._id || ""
                  : ele?.room || "",
              room_name:
                typeof ele?.room_name == "object"
                  ? ele?.room_name?._id || ""
                  : ele?.room_name || "",
              base_rate: ele.base_rate,
              extra_bed_adult: ele.extra_bed_adult,
              extra_bed_child: ele.extra_bed_child,
              adult_rate: ele.adult_rate,
              child_rate: ele.child_rate,
            });
          });
          if (el?.rooms?.length)
            temp.push({
              _id: el._id,
              rate_plan:
                typeof el?.rate_plan == "object"
                  ? el?.rate_plan?._id || ""
                  : el?.rate_plan || "",
              per_room: el.per_room,
              per_guest: el.per_guest,
              rooms: tempRoomTypies,
            });
        });
      }
      self.itemsRatePlanData = temp;
    },
    clearAddNewVoucherDataFunc() {
      const self = this;
      const linkItems = {
        link: `signup/rateplan/${self.propertyID}`,
        name: "SignupRatePlanScreen",
        params: { propertyId: self.propertyID },
      };
      self.addNewRatePlan = {
        newrateplan: {
          rateplan: "",
          rateplanper: "night",
          roomtypes: [],
        },
        newrateplanroomtype: "",
      };
      EventBus.$emit("changePathname", linkItems);
      EventBus.$emit("loadingPage");
    },
    async changeStep(linkItems) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step3?property_id=${self.propertyID}&next=${0}`,
          null,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("changePathname", linkItems);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    addNewVoucherDataFunc() {
      const self = this;
      var tempRoomTypies = [];
      var temp = self.roomData.rateplans;
      var numofchoices = 0;
      var numofchoicesdata = 0;

      EventBus.$emit("loadingtillend");

      self.addNewRatePlan.newrateplan.roomtypes.map((el) => {
        const tempRoomName = self.itemsRoomType.find(
          (p) => p.value == el.value
        );

        numofchoices = numofchoices + 1;

        if (self?.addNewRatePlan?.newrateplan?.rateplanper == "night") {
          if (el.customer_normal < el.customer_max) {
            if (el.baserateprice && el.extraadult && el.extrachild)
              numofchoicesdata = numofchoicesdata + 1;
          } else {
            if (el.baserateprice) numofchoicesdata = numofchoicesdata + 1;
          }
        } else {
          if (el.adultrate && el.childrate)
            numofchoicesdata = numofchoicesdata + 1;
        }

        if (el?._id)
          tempRoomTypies.push({
            room: tempRoomName?._id || el?._id,
            room_name: el?.value || "",
            base_rate:
              self?.addNewRatePlan?.newrateplan?.rateplanper == "night"
                ? Number(el?.baserateprice) || 0
                : 0,
            extra_bed_adult:
              self?.addNewRatePlan?.newrateplan?.rateplanper == "night"
                ? Number(el?.extraadult) || 0
                : 0,
            extra_bed_child:
              self?.addNewRatePlan?.newrateplan?.rateplanper == "night"
                ? Number(el?.extrachild) || 0
                : 0,
            adult_rate:
              self?.addNewRatePlan?.newrateplan?.rateplanper == "person"
                ? Number(el?.adultrate) || 0
                : 0,
            child_rate:
              self?.addNewRatePlan?.newrateplan?.rateplanper == "person"
                ? Number(el?.childrate) || 0
                : 0,
          });
      });
      const findRatePlan = self.itemsRatePlan?.find(
        (p) => p.value == self.addNewRatePlan.newrateplan.rateplan
      );

      const obj = {
        rate_plan: self.addNewRatePlan.newrateplan.rateplan,
        master_rate_plan_price: null,
        per_room:
          self.addNewRatePlan.newrateplan.rateplanper === "night"
            ? true
            : false,
        per_guest:
          self.addNewRatePlan.newrateplan.rateplanper === "person"
            ? true
            : false,
        rooms: tempRoomTypies,
        advance_booking: self.advance_booking,
        is_signup: true,
        age_of_voucher: {
          length: 365,
          expire_date: null,
        },
      };
      if (findRatePlan?.name == self.$t("SignupStep3.room_with_breakfast")) {
        obj.food_service = "breakfast";
      }

      if (
        !obj.rate_plan ||
        obj.rooms.length == 0 ||
        numofchoices !== numofchoicesdata
      ) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        self.updateRatePlanTypes(obj);
      }
    },
    async updateRatePlanTypes(item) {
      const self = this;
      const obj = item;
      // console.log('obj: ', obj)
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          self.clearAddNewVoucherDataFunc();
          EventBus.$emit("loadingPage");
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    addNewRatePlanRoomtype() {
      const self = this;
      if (typeof self.addNewRatePlan.newrateplanroomtype == "string") {
        var index = self.addNewRatePlan.newrateplan.roomtypes.findIndex(
          (p) => p._id == self.addNewRatePlan.newrateplanroomtype
        );
        var nameofrateplan = self.itemsRoomType.find(
          (p) => p._id == self.addNewRatePlan.newrateplanroomtype
        );
        if (index > -1) {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.alreadyhavedata"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } else {
          self.canaddroom = false;
          self.componentKey = 0;
          self.addNewRatePlan.newrateplan.roomtypes.push({
            _id: nameofrateplan._id,
            showname: `${nameofrateplan.roomname} (${nameofrateplan.name})`,
            name: nameofrateplan.name,
            value: self.addNewRatePlan.newrateplanroomtype,
            roomname: nameofrateplan.roomname,
            roomnameid: nameofrateplan.roomnameid,
            customer_normal: nameofrateplan?.customer_normal || 0,
            customer_max: nameofrateplan?.customer_max || 0,
            baserateprice: "",
            extraadult: "",
            extrachild: "",
            adultrate: "",
            childrate: "",
            hover: false,
          });
          // EventBus.$emit('loadingPage')
          setTimeout(() => {
            self.closeDialogAddRoomName();
            self.addNewRatePlan.newrateplanroomtype = "";
            self.setRoomType();
          }, 200);
        }
      } else {
        self?.addNewRatePlan?.newrateplanroomtype?.map((el) => {
          var index = self.addNewRatePlan.newrateplan.roomtypes.findIndex(
            (p) => p._id == el
          );
          var nameofrateplan = self.itemsRoomType.find((p) => p._id == el);
          if (index > -1) {
            swal(
              self.$t("Alert.warn_title"),
              self.$t("Alert.alreadyhavedata"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          } else {
            self.canaddroom = false;
            self.componentKey = 0;
            self.addNewRatePlan.newrateplan.roomtypes.push({
              _id: nameofrateplan._id,
              showname: `${nameofrateplan.roomname} (${nameofrateplan.name})`,
              name: nameofrateplan.name,
              value: el,
              roomname: nameofrateplan.roomname,
              roomnameid: nameofrateplan.roomnameid,
              customer_normal: nameofrateplan?.customer_normal || 0,
              customer_max: nameofrateplan?.customer_max || 0,
              baserateprice: "",
              extraadult: "",
              extrachild: "",
              adultrate: "",
              childrate: "",
              hover: false,
            });
            // EventBus.$emit('loadingPage')
            setTimeout(() => {
              self.closeDialogAddRoomName();
              self.addNewRatePlan.newrateplanroomtype = "";
              self.setRoomType();
            }, 200);
          }
        });
      }
    },
    closeDialogAddRoomName() {
      const self = this;
      self.addNewRatePlan.newrateplanroomtype = [];
      self.dialogAddRoomName = false;
    },
    removeNewRatePlanRoomtype(item) {
      const self = this;
      var index = self.addNewRatePlan.newrateplan.roomtypes.findIndex(
        (p) => p._id == item._id
      );
      if (index > -1) {
        self.addNewRatePlan.newrateplan.roomtypes.splice(index, 1);
        self.setRoomType();
      } else {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.notremovedata"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    goback() {
      const self = this;
      var item = {
        link: `signup/rateplan/${self.propertyID}`,
        name: "SignupRatePlanScreen",
        params: { propertyId: self.propertyID },
      };
      EventBus.$emit("changePathname", item);
    },
    toggleAllRooms() {
      const self = this;
      self.$nextTick(() => {
        if (self.selectedAllRooms) {
          self.addNewRatePlan.newrateplanroomtype = [];
        } else {
          let tempArray = self?.itemsRoomType?.slice()?.map((a) => a._id);
          self.addNewRatePlan.newrateplanroomtype = tempArray;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";

.moving-left {
  /* height: 100px; */
  /* position: relative; */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0px);
  /* width: 100px; */
}

.moving-left:hover {
  transform: translateX(-10px);
}

.hover_numberbtn:hover {
  border: solid 1px transparent;
  color: #fff !important;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.btn.btn-addtype:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}

.v-input__slot {
  border: 1px solid var(--border-gray) !important;
}

.title-green {
  color: var(--green) !important;
}

.title-gray {
  color: var(--gray) !important;
}

.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  min-height: 42px !important;
  height: fit-content !important;
}
</style>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}
@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
